<template>
    <div class=container>
        <span class=Title>Copyright Notice</span>
        <p class=description>This material is presented to ensure timely dissemination of scholarly and technical work.
            Copyright and all rights therein are retained by authors or by other copyright holders. All persons copying
            this information are expected to adhere to the terms and constraints invoked by each author's copyright. In
            most cases, these works may not be reposted without the explicit permission of the copyright holder.
        </p>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.container {
    /* letter-spacing: .05em; */
    margin-top: 20px;
    line-height: 1.4em;
    font-size: 18px;
    align-content: center;
    min-width: 1000px;
}

.Title {
    font-size: 32px;
    text-align: left;
    margin-left: 20rem;
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
    padding-top: 1em;
}

.description {
    margin-left: 20rem;
    width: 50%;
    text-align: left;
    font-size: 18px;
    font-family: 'Helvetica', 'Arial', sans-serif;
}
</style>